import React, { useContext, useState, useEffect, useRef } from 'react';
import SelectSearch, { fuzzySearch } from 'react-select-search';

import { FormContext } from '../context';

import './select.css';

import loadComponents from '../../Loadable';

const Label = loadComponents('form-label');

export default ({ field }) => {
  const { getValue, handleChange } = useContext(FormContext);

  const select = useRef(null);

  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const initOptions = () => {
    const allOptions = [];
    field.options.forEach(option => {
      if (!option.disabled) {
        allOptions.push({
          value: option.value || option.option,
          name: option.option,
        });
      }
    });

    Promise.all(allOptions).then(items => {
      setOptions(items);
    });
  };

  const detectClick = e => {
    const isSelect = select.current && select.current?.contains(e.target);

    if (isSelect) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    initOptions();
    window.addEventListener('mousedown', detectClick, false);
    return () => {
      window.removeEventListener('mousedown', detectClick, false);
    };
  }, []);

  const updateValue = val => {
    handleChange({ target: { name: field.name, value: val } });
    setIsOpen(false);
  };

  const isInvalid = field.required && !field.value;

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      htmlFor={field.id}
      ref={node => {
        select.current = node;
      }}
    >
      {field.label && <Label label={field.label} required={field.required} />}
      <SelectSearch
        id={field.id}
        className={`select-search ${isInvalid ? 'invalid' : ''}`}
        options={options}
        filterOptions={fuzzySearch}
        onChange={val => updateValue(val)}
        value={[String(field.value || getValue(field.name))]}
        name={field.name}
        placeholder={field.placeholder || 'Start typing to search...'}
        emptyMessage="No options found"
        onFocus={() => setIsOpen(true)}
        printOptions={isOpen ? 'always' : 'auto'}
        search
      />
    </label>
  );
};
